import React from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

function Hosting() {
    const navigate = useNavigate();
  return (
    <div className='d-grid justify-content-center p-2' style={{minHeight: '50vh'}}>
    <h2 className='text-center mb-3'>Hosting Package</h2>
    
    <Container>
        <Row className='bg-dark rounded p-3 mb-2'>
            <Col>
            <h3>Includes:</h3>
                <ul>
                    <li>Provide Your Website: If you've already got a website and want to host it on our servers located in Central Washington then this package is for you!</li>
                    <li>Basic Hosting: Reliable hosting with sufficient resources for small websites. SSL certificate included for secure connections.</li>
                    <li>Basic Support: Email support with a response time of up to 48 hours.</li>
                </ul>
            </Col>
        </Row>
        <Row>
            <Col><p>Ideal For: Clients who already have a website but need reliable, secure hosting.</p></Col>
        </Row>
        <Row>
            <Col className='d-grid justify-content-start'>Cost: $20/month</Col>
            <Col className='d-grid justify-content-end'>Initial Setup Fee: $20</Col>
        </Row>
        <Row className='d-grid justify-content-center mt-3'>
            <Col>
                <Button color='primary' onClick={() => navigate('/products/contract')} disabled>Buy Now!</Button>
            </Col>
        </Row>
    </Container>
</div>
  )
}

export default Hosting
import React, { useState } from 'react'
import { Button, Col, Container, Row, Tooltip } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

function Starter() {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const navigate = useNavigate();
  return (
    <div className='d-grid justify-content-center p-2' style={{minHeight: '50vh'}}>
        <h2 className='text-center mb-3'>Starter Package</h2>
        
        <Container>
            <Row className='bg-dark rounded p-3 mb-2'>
                <Col>
                <h3>Includes:</h3>
                    <ul>
                        <li>Templated Website Design: Choose from a range of professional templates. Customization of colors, fonts, and basic layout to match your brand.</li>
                        <li>Basic Hosting: Reliable hosting with sufficient resources for small websites. SSL certificate included for secure connections.</li>
                        <li>Monthly Updates: Regular updates to content and minor design changes (up to 2 hours per month).</li>
                        <li>Basic Support: Email support with a response time of up to 48 hours.</li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col className='d-grid justify-content-start'>Cost: $150/month</Col>
                <Col className='d-grid justify-content-end'>Initial Setup Fee: $200</Col>
            </Row>
            <Row className='d-grid justify-content-center mt-3'>
                <Col id='starterBuyBtn'>
                    <Button color='primary' onClick={() => navigate('/products/contract')} disabled>Buy Now!</Button>
                    <Tooltip
                        placement='bottom'
                        isOpen={tooltipOpen}
                        target="starterBuyBtn"
                        toggle={toggle}
                        >
                        Coming Soon!
                    </Tooltip>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Starter
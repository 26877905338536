import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { URLContext } from '../../store/URLContext';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import Passcode from '../Admin/Passcode';

function Portfolio() {

  const [data, setData] = useState();
  const {url} = useContext(URLContext)

  useEffect(() => {
    const getData = async () => {
      const tasks = await axios.get(`${url}/projects`)
      setData(tasks.data);
    }
    getData()
  },[])

  while(!data){
    return(<h2>Loading...</h2>)
  }

  const displayProjects = data.map((project) => {
    return(
      <div key={project.id}>
        <h2>{project.name}</h2>
        <img src={project.img}></img>
      </div>
    )
    
  })

  return (
    <div>
      {displayProjects}
    </div>
  )
}

export default Portfolio
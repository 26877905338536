import React from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { FaLinkedin, FaInstagram, FaTwitter, FaFacebook } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import larimertech from '../Photos/LarimerTech Logo (no bg).png';

function Footer() {
  return (
    <footer className="bg-dark text-light text-center py-4 mt-5" style={{ width: '100%' }}>
        <Row>
          <Col sm={2} className="mb-3 d-grid">
            <img src={larimertech} alt='Larimer Tech' className='logo' />
          </Col>
          <Col sm={3} className="mb-3 d-grid align-content-center">
            <h4>Professional Web Development Services in Central Washington</h4>
          </Col>
          <Col sm={3} className="mb-3">
            <h5>Quick Links</h5>
            <ListGroup className="list-unstyled">
              <ListGroupItem className="bg-dark border-0 p-0">
                <a href="#hero" className="text-light">Home</a>
              </ListGroupItem>
              <ListGroupItem className="bg-dark border-0 p-0">
                <a href="#about" className="text-light">About</a>
              </ListGroupItem>
              <ListGroupItem className="bg-dark border-0 p-0">
                <a href="#services" className="text-light">Services</a>
              </ListGroupItem>
              <ListGroupItem className="bg-dark border-0 p-0">
                <a href="#portfolio" className="text-light">Portfolio</a>
              </ListGroupItem>
              <ListGroupItem className="bg-dark border-0 p-0">
                <a href="#contact" className="text-light">Contact</a>
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Col sm={3} className="mb-3">
            <h5>Contact Information</h5>
            <p>Email: <a href="mailto:larimertech@gmail.com" className="text-light">larimertech@gmail.com</a></p>
            <div className="d-flex justify-content-center">
              <a href="https://linkedin.com/in/dylan-larimer/" className="text-light mx-2"><FaLinkedin size={24} /></a>
              <a href="https://www.instagram.com/larimertech?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" className="text-light mx-2"><FaInstagram size={24} /></a>
            </div>
          </Col>
        </Row>
        <div className="text-center mt-4">
          <p>&copy; 2024 LarimerTech. All rights reserved.</p>
        </div>
    </footer>
  );
}

export default Footer;

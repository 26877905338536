import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

function Standard() {
    const navigate = useNavigate();
  return (
    <div className='d-grid justify-content-center p-2' style={{minHeight: '50vh'}}>
    <h2 className='text-center mb-3'>Standard Package</h2>
    
    <Container>
        <Row className='bg-dark rounded p-3 mb-2'>
            <Col>
            <h3>Includes:</h3>
                <ul>
                    <li>Custom Web Design: Tailored design to meet specific business needs. Consultation to understand brand and design preferences. More extensive customization options compared to the Starter Package.</li>
                    <li>Standard Hosting: More resources and faster performance compared to basic hosting. SSL certificate included.</li>
                    <li>Monthly Updates and Maintenance: Regular updates to content and design changes (up to 5 hours per month). Performance optimization and security updates.</li>
                    <li>Priority Support: Email and phone support with a response time of up to 24 hours.</li>
                </ul>
            </Col>
        </Row>
        <Row>
            <Col className='d-grid justify-content-start'>Cost: $250/month</Col>
            <Col className='d-grid justify-content-end'>Initial Setup Fee: $300</Col>
        </Row>
        <Row className='d-grid justify-content-center mt-3'>
            <Col>
                <Button color='primary' onClick={() => navigate('/products/contract')} disabled>Buy Now!</Button>
            </Col>
        </Row>
    </Container>
</div>
  )
}

export default Standard
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Container, Row } from 'reactstrap'

function Premium() {
    const navigate = useNavigate();
  return (
    <div className='d-grid justify-content-center p-2' style={{minHeight: '50vh'}}>
    <h2 className='text-center mb-3'>Premium Package</h2>
    
    <Container>
        <Row className='bg-dark rounded p-3 mb-2'>
            <Col>
            <h3>Includes:</h3>
                <ul>
                    <li>Advanced Custom Web Design: Highly tailored design with detailed consultation. Advanced features such as animations, custom graphics, and interactive elements. E-commerce capabilities if needed.</li>
                    <li>Advanced Hosting: High-performance hosting with enhanced security features. SSL certificate included. Daily backups and 99.9% uptime guarantee.</li>
                    <li>Bi-weekly Updates and Maintenance: Frequent updates to content and design (up to 10 hours per month). Comprehensive performance optimization, security updates, and troubleshooting.</li>
                    <li>Priority Support: Email, phone, and chat support with a response time of up to 12 hours.</li>
                </ul>
            </Col>
        </Row>
        <Row>
            <Col className='d-grid justify-content-start'>Cost: $350/month</Col>
            <Col className='d-grid justify-content-end'>Initial Setup Fee: $400</Col>
        </Row>
        <Row className='d-grid justify-content-center mt-3'>
            <Col>
                <Button color='primary' onClick={() => navigate('/products/contract')} disabled>Buy Now!</Button>
            </Col>
        </Row>
    </Container>
</div>
  )
}

export default Premium
import React, { useEffect, useState } from 'react';
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { useLocation } from 'react-router-dom';

const PaymentForm = () => {
  const [packageInfo, setPackageInfo] = useState(null);
  const location = useLocation();
  const [formData, setFormData] = useState({
    amount: '',
    clientEmail: '',
    cardNumber: '',
    expMonth: '',
    expYear: '',
    cvc: ''
  });

  useEffect(() => {
    const savedPackage = JSON.parse(sessionStorage.getItem('selectedPackage'));
    if (savedPackage) {
      setPackageInfo(savedPackage);
      setFormData({ ...formData, amount: savedPackage.setupFee, clientEmail: savedPackage.clientEmail });
    }

    const queryParams = new URLSearchParams(location.search);
    const signingCompleted = queryParams.get('completed');
    if (signingCompleted) {
      sessionStorage.removeItem('selectedPackage');
    }
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:3002/process-payment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Payment processed successfully!');
      } else {
        throw new Error('Payment failed');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      alert('There was an error processing your payment.');
    }
  };

  return (
    <Container>
      <h2>Amount Due: ${packageInfo && packageInfo.setupFee}</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="clientEmail">Client Email</Label>
          <Input
            type="email"
            name="clientEmail"
            id="clientEmail"
            placeholder="Client Email"
            value={formData.clientEmail}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="cardNumber">Card Number</Label>
          <Input
            type="text"
            name="cardNumber"
            id="cardNumber"
            placeholder="Card Number"
            value={formData.cardNumber}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="expMonth">Expiry Month</Label>
          <Input
            type="text"
            name="expMonth"
            id="expMonth"
            placeholder="MM"
            value={formData.expMonth}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="expYear">Expiry Year</Label>
          <Input
            type="text"
            name="expYear"
            id="expYear"
            placeholder="YYYY"
            value={formData.expYear}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="cvc">CVC</Label>
          <Input
            type="text"
            name="cvc"
            id="cvc"
            placeholder="CVC"
            value={formData.cvc}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <Button type="submit" color="primary">Submit</Button>
      </Form>
    </Container>
  );
};

export default PaymentForm;

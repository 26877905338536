import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap'

function Products() {

    const navigate = useNavigate();

  return (
    <Container className='text-center'>
        <h2 className='my-3'>Select a Package to Get Started!</h2>

        <div className=''></div>
        <Row className='d-flex justify-content-center'>
            <Col md={3} className='m-2 d-flex justify-content-center '>
                <Card className='bg-dark text-light py-2 productCard'>
                    <CardHeader>
                        <h3>Hosting Package</h3>
                    </CardHeader>
                    <CardBody className='d-grid justify-content-center align-content-center'>
                        <ul className='text-left'>
                            <li>Basic Hosting</li>
                            <li>Basic Support (email)</li>
                        </ul>
                    </CardBody>
                    <CardFooter>
                        <h4>$20</h4>
                        <Button color='primary' onClick={() => navigate('/products/hosting')}>Buy Now!</Button>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={3} className='m-2 d-flex justify-content-center '>
                <Card className='bg-dark text-light py-2 productCard'>
                    <CardHeader>
                        <h3>Starter Package</h3>
                    </CardHeader>
                    <CardBody className='d-grid justify-content-center align-content-center'>
                        <ul className='text-left'>
                            <li>Templated Website Design</li>
                            <li>Basic Hosting</li>
                            <li>Monthly updates</li>
                            <li>Basic Support (email)</li>
                        </ul>
                    </CardBody>
                    <CardFooter>
                        <h4>$150</h4>
                        <Button color='primary' onClick={() => navigate('/products/starter')}>Buy Now!</Button>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={3} className='m-2 d-flex justify-content-center '>
                <Card className='bg-dark text-light py-2 productCard'>
                    <CardHeader>
                        <h3>Standard Package</h3>
                    </CardHeader>
                    <CardBody className='d-grid justify-content-center align-content-center'>
                        <ul className='text-left'>
                            <li>Custom Web Design</li>
                            <li>Standard hosting</li>
                            <li>Monthly Updates & Maintenance</li>
                            <li>Priority Support (email & phone)</li>
                        </ul>
                    </CardBody>
                    <CardFooter>
                        <h4>$250</h4>
                        <Button color='primary' onClick={() => navigate('/products/standard')}>Buy Now!</Button>
                    </CardFooter>
                </Card>
            </Col>
            <Col md={3} className='m-2 d-flex justify-content-center '>
                <Card className='bg-dark text-light py-2 productCard'>
                    <CardHeader>
                        <h3>Premium Package</h3>
                    </CardHeader>
                    <CardBody className='d-grid justify-content-center align-content-center'>
                        <ul className='text-left'>
                            <li>Advanced Custom Web Design</li>
                            <li>Advanced Hosting With Enhanced Security</li>
                            <li>Bi-Weekly Updates & Maintenance</li>
                            <li>Priority Support (email, phone, & chat)</li>
                            <li>E-Commerce Capabilities (if needed)</li>
                        </ul>
                    </CardBody>
                    <CardFooter>
                        <h4>$350</h4>
                        <Button color='primary' onClick={() => navigate('/products/premium')}>Buy Now!</Button>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
    </Container>
  )
}

export default Products

{/* <section id="portfolio" className="py-5">
        <div className="container">
          <h2>Our Work</h2>
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="card bg-dark text-light">
                <img src={screenshot} className="card-img-top" alt="Project Screenshot" />
                <div className="card-body">
                  <h3 className="card-title">LarimerTech</h3>
                  <p className="card-text">Professional landing page built for LarimerTech.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
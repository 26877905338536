import { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderBar from './components/HeaderBar/HeaderBar';
import Shop from './components/Pages/Shop';
import { URLContext, urlCon } from './store/URLContext';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Temp from './components/Pages/Temp';
import Home from './components/Pages/Home';
import Contact from './components/Pages/Contact';
import Portfolio from './components/Pages/Portfolio';
import Login from './components/Admin/Login';
import Admin from './components/Admin/Admin';
import Footer from './components/Footer';
import Products from './components/Pages/Products';
import Hosting from './components/Pages/Packages/Hosting';
import Starter from './components/Pages/Packages/Starter';
import Standard from './components/Pages/Packages/Standard';
import Premium from './components/Pages/Packages/Premium';
import ContractForm from './components/Pages/ContractForm';
import { ProductsProvider } from './store/ProductsContext';
import PaymentForm from './components/Pages/PaymentForm';

function App() {

  const [url, setUrl] = useState(urlCon);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const completed = query.get('completed');
    if (completed === 'true') {
      navigate('/payment');
    }
  }, [location]);

  return (
    <URLContext.Provider value={{url, setUrl}}>
      <ProductsProvider>
    <div className="App-header">
      <HeaderBar />
      
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/portfolio' element={<Portfolio/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/shop' element={<Shop/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/admin' element={<Admin/>}/>
        <Route path='/products' element={<Products />} />
        <Route path='/products/hosting' element={<Hosting />} />
        <Route path='/products/starter' element={<Starter />} />
        <Route path='/products/standard' element={<Standard />} />
        <Route path='/products/premium' element={<Premium />} />
        <Route path='/products/contract' element={<ContractForm />} />
        <Route path='/payment' element={<PaymentForm/>} />
      </Routes>

      <Footer />
    </div>
    </ProductsProvider>
    </URLContext.Provider>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Shop() {
  const [pingData, setPingData] = useState(null);
  const [error, setError] = useState(null);
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.etsy.com/v3/application/openapi-ping', {
          headers: {
            'x-api-key': apiKey
          }
        });

        if (response.status === 200) {
          setPingData(response.data);
        } else {
          setError('Oops');
        }
      } catch (error) {
        console.error('Error:', error.message);
        setError('Oops');
      }
    };

    fetchData();

    // Cleanup function (optional)
    return () => {
      // Perform any cleanup (if necessary)
    };
  }, []);

  return (
    <div>
      <h1>Ping Data:</h1>
      {pingData ? (
        <pre>{JSON.stringify(pingData, null, 2)}</pre>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Shop;

// An attempt at Etsy API. I would really like to get this working.

// ProductsContext.js
import React, { createContext, useState, useContext } from 'react';

const ProductsContext = createContext();

export const useProducts = () => {
  return useContext(ProductsContext);
};

export const ProductsProvider = ({ children }) => {
  const [selectedPackage, setSelectedPackage] = useState();
  const [products, setProducts] = useState([
    {
        package: 'Hosting',
        servicesProvided: `Provide Your Website: If you've already got a website and want to host it on our servers located in Central Washington then this package is for you! \n
        Basic Hosting: Reliable hosting with sufficient resources for small websites. SSL certificate included for secure connections. \n
        Basic Support: Email support with a response time of up to 48 hours.`,
        setupFee: '20',
        monthlyFee: '20',
        terminationFee: '0',
        totalPayment: '0',
        supportAndMaintenance: `Reliable hosting with sufficient resources for small websites. SSL certificate included for secure connections.\n
        Email support with a response time of up to 48 hours.`
      },
      {
        package: 'Starter',
        servicesProvided: `Templated Website Design: Choose from a range of professional templates. Customization of colors, fonts, and basic layout to match your brand.\n
        Basic Hosting: Reliable hosting with sufficient resources for small websites. SSL certificate included for secure connections.\n
        Monthly Updates: Regular updates to content and minor design changes (up to 2 hours per month).\n
        Basic Support: Email support with a response time of up to 48 hours.`,
        setupFee: '200',
        monthlyFee: '150',
        terminationFee: '1000',
        totalPayment: '1000',
        supportAndMaintenance: `Regular updates to content and minor design changes (up to 2 hours per month).
        Email support with a response time of up to 48 hours.`
      },
      {
        package: 'Standard',
        servicesProvided: `Custom Web Design: Tailored design to meet specific business needs. Consultation to understand brand and design preferences. More extensive customization options compared to the Starter Package. \n
        Standard Hosting: More resources and faster performance compared to basic hosting. SSL certificate included. \n
        Monthly Updates and Maintenance: Regular updates to content and design changes (up to 5 hours per month). Performance optimization and security updates. \n
        Priority Support: Email and phone support with a response time of up to 24 hours.`,
        setupFee: '300',
        monthlyFee: '250',
        terminationFee: '1200',
        totalPayment: '1200',
        supportAndMaintenance: 'Email and phone support with a response time of up to 24 hours. \n Regular updates to content and design changes (up to 5 hours per month). Performance optimization and security updates.'
      },
      {
        package: 'Premium',
        servicesProvided: `Advanced Custom Web Design: Highly tailored design with detailed consultation. Advanced features such as animations, custom graphics, and interactive elements. E-commerce capabilities if needed.\n
        Advanced Hosting: High-performance hosting with enhanced security features. SSL certificate included. Daily backups and 99.9% uptime guarantee.\n
        Bi-weekly Updates and Maintenance: Frequent updates to content and design (up to 10 hours per month). Comprehensive performance optimization, security updates, and troubleshooting.\n
        Priority Support: Email, phone, and chat support with a response time of up to 12 hours.`,
        setupFee: '400',
        monthlyFee: '350',
        terminationFee: '1500',
        totalPayment: '1500',
        supportAndMaintenance: 'Email, phone, and chat support with a response time of up to 12 hours.\n Frequent updates to content and design (up to 10 hours per month). Comprehensive performance optimization, security updates, and troubleshooting.'
      }
  ]);

  return (
    <ProductsContext.Provider value={{ products, setProducts, selectedPackage, setSelectedPackage }}>
      {children}
    </ProductsContext.Provider>
  );
};

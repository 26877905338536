import React, { useState } from 'react'
import { Button, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Tooltip } from 'reactstrap'
import { useNavigate } from 'react-router'
import useWindowDimensions from './getWindowDimensions';

function NavMenu() {

  const navigate = useNavigate();
  const { height, width} = useWindowDimensions();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);


  const setNavMenu = () => {
    
    if(width < 768){ //Are we on a mobile device || are we using a narrow window size?
    return( //If yes, give the NavBar a dropdown menu.
      <Row className='mb-2 justify-content-center'>
      <UncontrolledDropdown group className='w-50'>

        <Button className='w-75' color='primary' onClick={() => navigate(`/`)}>Home</Button>
        <DropdownToggle color='primary' caret />

        <DropdownMenu>
        <a href='/#services'><DropdownItem onClick={null}>
            Our Work
          </DropdownItem></a>
          <DropdownItem onClick={() => navigate('/products')}>
            Products
          </DropdownItem>
          <DropdownItem onClick={() => navigate(`/shop`)}>
            Shop
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      </Row>
      
    )
  }else{
    return(//If not, give each page a dedicated button!
      <Row className='mb-2 px-5'>
        <Col className='mx-3 '><Button color='primary' className='w-75' onClick={() => navigate(`/`)}>Home</Button></Col>
        <Col className='mx-3 '><a href='/#services'><Button color='primary' className='w-75' onClick={null}>Our Work</Button></a></Col>
        <Col className='mx-3 '><Button color='primary' className='w-75' onClick={() => navigate('/products')}>Products</Button></Col>
        <Col className='mx-3 '><Button color='primary' className='w-75' id='shopButton' href="https://larimertech.etsy.com">Shop</Button></Col>

        <Tooltip //Check out my Etsy Shop! https://larimertech.etsy.com
          placement='bottom'
          isOpen={tooltipOpen}
          target="shopButton"
          toggle={toggle}
        >
          Check out my Etsy!
      </Tooltip>
      </Row>
    )
  }
  }

  return (
    <div>{setNavMenu()}</div>
  )
}

export default NavMenu

//This component sets up the NavBar. It uses the getWindowDimension component to get the size of 
//the users window. This will then adjust the placement of the nav bar accordingly making the nav bar
//look pleasant on a mobile device.
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Passcode() {

    
  const [clickCount, setClickCount] = useState(0);
  const navigate = useNavigate();

  console.log(`Clicked!`)

    const passcode = () => {
        setClickCount(prevCount => prevCount + 1); // Increment click count
      };

    useEffect(() => {
        let timeoutId;
    
        const resetClickCount = () => {
          setClickCount(0); // Reset click count
        };
    
        if (clickCount === 3) { // Adjusted to 3 to match your original logic
          // Redirect to login page if the sequence is completed
          window.location.href = '/login'; 
          return () => {
            // Cleanup: Clear the timeout when the component unmounts
            clearTimeout(timeoutId);
          };
        }
    
        // Set a timeout to reset click count after 3 seconds
        timeoutId = setTimeout(() => {
          resetClickCount();
        }, 3000);
    
        // Cleanup: Clear the timeout when the component unmounts
        return () => {
          clearTimeout(timeoutId);
        };
      }, [clickCount]); // Dependency added to ensure useEffect re-runs when clickCount changes

  return (
    <div></div>
  )
}

export default Passcode
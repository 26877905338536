import React from 'react'
import Temp from './Temp'
import { Col, Container, Row } from 'reactstrap'
import Contact from './Contact'
import LarimerTech from '../../Photos/screenshot.png'
import HairByMacenna from '../../Photos/HairByMacenna.png'

function Home() {
  return (
    <div>
      <div>
      {/* Hero Section */}

      {/* About Section */}
      <section id="about" className="py-5">
        <div className="container">
          <h2>About Us</h2>
          <p>At LarimerTech, we specialize in creating stunning, functional websites tailored to your needs. With our local hosting services and continuous support, your online presence is in good hands.</p>
          <p>Reliable local hosting, personalized designs, and ongoing maintenance to keep your site up-to-date.</p>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="bg-dark py-5">
        <div className="container">
          <h2>Our Services</h2>
          <div className="row">
            <div className="col-md-6 mb-4">
              <h3>Custom Web Design</h3>
              <p>Our custom web design service ensures your website is unique and tailored to your brand. We work closely with you to create a site that reflects your vision.</p>
            </div>
            <div className="col-md-6 mb-4">
              <h3>Templated Websites</h3>
              <p>Choose from our collection of professionally designed templates to get your website up and running quickly without sacrificing quality.</p>
            </div>
            <div className="col-md-6 mb-4">
              <h3>Local Web Hosting</h3>
              <p>Our servers, located in Central Washington, provide fast, reliable hosting solutions to keep your website running smoothly.</p>
            </div>
            <div className="col-md-6 mb-4">
              <h3>Continued Upgrades and Maintenance</h3>
              <p>We offer continuous upgrades and maintenance services to ensure your website remains secure, updated, and performing optimally.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Portfolio Section */}
      <section id="portfolio" className="py-5">
        <div className="container">
          <h2>Our Work</h2>
          <div className="row">

            <div className="col-md-4 mb-4">
            <a href='https://hairbymacenna.larimer.tech' style={{textDecoration: 'none'}}><div className="card bg-dark text-light">
                <img src={HairByMacenna} className="card-img-top" alt="Project Screenshot" />
                <div className="card-body">
                  <h3 className="card-title">HairByMacenna</h3>
                  <p className="card-text">Professional landing page and scheduling service built for HairByMacenna.</p>
                </div>
              </div></a>
            </div>

            <div className="col-md-4 mb-4">
              <div className="card bg-dark text-light">
                <img src={LarimerTech} className="card-img-top" alt="Project Screenshot" />
                <div className="card-body">
                  <h3 className="card-title">LarimerTech</h3>
                  <p className="card-text">Professional landing page built for LarimerTech.</p>
                </div>
              </div>
            </div>

            
            {/* Add more portfolio items as needed */}
          </div>
        </div>
      </section>

            {/* CTA Section */}
      <section id="cta" className="text-center py-5">
        <div className="container">
          
        </div>
      </section>
      

      <Contact />

      </div>
    </div>
  )
}

export default Home
import React, { useState, useEffect } from 'react';
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { useProducts } from '../../store/ProductsContext';

const ContractForm = () => {
  const {selectedPackage, setSelectedPackage} = useProducts();
  const [formData, setFormData] = useState({
    package: '',
    clientName: '',
    clientEmail: '',
    servicesProvided: '',
    setupFee: '',
    monthlyFee: '',
    paymentMethod: 'Paid by Invoice',
    paymentDueDay: '',
    endDate: '',
    terminationFee: '',
    totalPayment: '',
    supportAndMaintenance: ''
  });
  const { products } = useProducts();

  useEffect(() => {
    if (selectedPackage) {
      setFormData({
        ...formData,
        package: selectedPackage.package,
        servicesProvided: selectedPackage.servicesProvided,
        setupFee: selectedPackage.setupFee,
        monthlyFee: selectedPackage.monthlyFee,
        terminationFee: selectedPackage.terminationFee,
        totalPayment: selectedPackage.totalPayment,
        supportAndMaintenance: selectedPackage.supportAndMaintenance
      });
    }
  }, [selectedPackage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData);

    sessionStorage.setItem('selectedPackage', JSON.stringify(selectedPackage));


    try {
      // Create Contract
      const contractResponse = await fetch('https://api.larimer.tech/create-contract', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const contractResult = await contractResponse.json();

      if (contractResponse.ok) {
        window.location.href = contractResult.signing_url;
      } else {
        throw new Error('Failed to create contract');
      }
    } catch (error) {
      console.error('Error processing request:', error);
      alert('There was an error processing your request.');
    }
  };

  return (
    <Container>
      <Form id="contract-form" onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="clientName">Client Name</Label>
          <Input
            type="text"
            name="clientName"
            id="clientName"
            placeholder="Client Name"
            value={formData.clientName}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="clientEmail">Client Email</Label>
          <Input
            type="email"
            name="clientEmail"
            id="clientEmail"
            placeholder="Client Email"
            value={formData.clientEmail}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="package">Package Name</Label>
          <Input
            type="select"
            name="package"
            id="package"
            value={selectedPackage ? JSON.stringify(selectedPackage) : ""}
            onChange={(e) => setSelectedPackage(JSON.parse(e.target.value))}
            required
          >
            <option value="" disabled>Select a service</option>
            {products.map((service, index) => (
            <option key={index} value={JSON.stringify(service)}>{service.package}</option>))}
          </Input>
        </FormGroup>
        <Input type="hidden" name="setupFee" value={formData.setupFee} />
        <Input type="hidden" name="monthlyFee" value={formData.monthlyFee} />
        <Button type="submit" color="primary">Submit</Button>
      </Form>
    </Container>
  );
};

export default ContractForm;

import React from 'react'
import { Button, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Tooltip } from 'reactstrap'
import NavMenu from './NavMenu';
import Passcode from '../Admin/Passcode';
import larimertech from '../../Photos/LarimerTech Logo (no bg).png'

function HeaderBar() {

  return (
    <div className='bg-dark' style={{width: '100%'}}>
      <div id='hiddenElement' onClick={() => <Passcode/>}/>
      <Row>
      <Col sm={2} className='d-grid'><img src={larimertech} className='logo rounded'/></Col>
      <Col>
      <section id="hero" className="text-center bg-dark py-5">
        <div className="container">
          <h1 className="display-4">Professional Web Development Services in Central Washington</h1>
          <p className="lead">Custom Web Design, Templated Solutions, Local Hosting, and Maintenance</p>
        </div>
      </section>
      </Col>
      </Row>
      
      <NavMenu /> 
      {/* ^^^ Show the user the fancy NavBar */}
      
    </div>
  )
}

export default HeaderBar
// This component will set the size and styling of the headerbar at the top of the page and give the 
// nav bar a place on the page.
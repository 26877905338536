import React, { useState } from 'react';
import { Alert } from 'reactstrap';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      const response = await fetch('https://api.larimer.tech/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
  
      if (response.ok) {
        setResponseMessage('Your message has been sent successfully!');
      } else {
        setResponseMessage('There was an error sending your message. Please try again.');
      }
    } catch (error) {
      setResponseMessage('There was an error sending your message. Please try again.');
    }
  
    setIsSubmitting(false);
  };
  

  return (
    <section id="contact" className="bg-dark text-light py-5">
      <div className="container">
      <h2>Ready to Start Your Project?</h2>
        <h2>Contact Us Today!</h2>
        <form onSubmit={handleSubmit} className="mb-4">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              className="form-control"
              rows={5}
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : 'Send'}
          </button>
        </form>
        {responseMessage && <Alert color='success'><p>{responseMessage}</p></Alert>}
        <p>Email: larimertech@gmail.com</p>
        {/* Social Media Links */}
        <div className="d-flex justify-content-center">
          <a href="https://linkedin.com/in/dylan-larimer/" className="mx-2 text-light">LinkedIn</a>
          <a href="https://www.instagram.com/larimertech?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" className="mx-2 text-light">Instagram</a>
        </div>
      </div>
    </section>
  );
}

export default Contact;
